<template>
  <div>
    <article>
      <h3 class="center">{{ appName }}App侵权举报流程指引</h3>
      <p style="text-align: right">公布时间：2022年05月【20】日</p>
      <p style="text-align: right">生效时间：2022年05月【20】日</p>
      <section>
        <h5>概述</h5>
        <p>
          {{ appName }}作为中立的网络服务提供方，{{ appName }}将不对用户上传、发表、转载的任何载体及内容进行形式保证，为了建立良好的内容秩序和保护权利人的合法权益
          ，任何权利人或权利人组织在使用服务的过程中发现相关内容侵犯了对应的合法权益，均可通过本指引所示范的流程发起举报，我们将按照相关法律法规的规定尽快进行处理。
        </p>
        <p class="bold">
          请注意：{{ appName }}已尽量按照我国最新有关法律、法规、部门规章、规范性文件（为了便于表述，以下简称“法律法规”），参照最新的国家标准制定并执行本指引。但由于前述规定更迭频繁，不同部门、人员对同一规定可能存在诸多理解等限制，若本指引内容与我国届时有效的法律法规规定存在不一致或矛盾之处，应以我国届时有效的法律法规之规定为准，我们亦将按照届时有效的法律法规之有关规定处理投诉人的具体诉求并保障其权利人或权利组织的合法权益。
        </p>
        <p>本指引适用的侵权投诉类型包含且不限于以下：</p>
        <p>1. 涉嫌侵犯权力人/权力组织名誉权、隐私权或肖像权的行为。</p>
        <p>2. 涉嫌冒充权利人或权力组织的行为，如冒用他人身份信息进行账号注册等。</p>
        <p>3. 涉嫌进行不正当竞争/诋毁/虚假宣传的行为，如发布侮辱诽谤、负面讹诈等内容。</p>
        <p>4.所发布内容涉嫌违反知识产权的行为（包括且不限于著作权、商标权、专利权等）。</p>
      </section>

      <section>
        <h5>一、侵权举报流程</h5>
        <p>
          1. 选择举报方式:
          如权利人/权利组织在{{ appName }}APP内发现相关内容侵犯其合法权益，可通过以下方式提交相关材料进行侵权举报，{{ appName }}将按照相关法律法规的规定尽责处理：
        </p>
        <ul>
          <li>1.1 方式一：通过产品内侵权举报按钮进行自助填写：<a @click="handleClick">点我去填写</a></li>
          <li>
            1.2 方式二：通过线下填写<a :href="reportProcessApplyFormUrl">侵权举报申诉表</a
            >，将填写完毕的表格及有效证明文件的对应扫描件通过电子邮件方式发送至{{ appName }}举报邮箱：kuaiyin_report@rings.net.cn。
          </li>
        </ul>
        <p>
          2.
          举报材料核实：在权利人/权利组织在上述举报方式中所提供材料及信息皆为真实、有效及合法的情况下，{{ appName }}会在收到权利人/权利组织举报通知后，结合构成侵权的初步证据和严重程度及时采取必要措施，对不符合法定要求及陈述信息不足的举报通知，{{ appName }}将在权利人/权利组织补正信息前暂不处理。
        </p>
        <p>3. 举报结果通知：</p>
        <ul>
          <li>
            3.1
            方式一：权利人/权利组织通过自助填写侵权举报信息并完成申请的，{{ appName }}将于三个工作日内通过官方【系统通知】的方式进行举报处理结果反馈（{{ appName }}APP－我的－消息中心－系统通知）。
          </li>
          <li>
            3.2
            方式二：权利人/权利组织通过线下邮件反馈侵权举报信息并完成申请的，{{ appName }}将于三个工作日内以邮件回复的方式进行处理结果反馈。
          </li>
        </ul>
      </section>

      <section>
        <h5>二、举报注意事项</h5>
        <p>1. 权利人/权利组织应确保举报材料及相关信息的真实性、完整性及合法性。</p>
        <p class="bold">1.1 确保身份信息真实、完整无误且具备合法性：</p>
        <ul>
          <li class="bold">(1) 个人投诉：需提交权利人身份证原件正反面照片或相关授权证明权利人主体资格的材料等。</li>
          <li class="bold">(2) 企业投诉：需提交权利组织的企业名称、联系人及有效联系方式、营业执照等。</li>
          <li class="bold">
            (3)
            代理人投诉：如委托他人代理投诉的，还需提供授权委托书，以及代理人姓名、联系方式、营业执照、身份证、相关代理人身份证明（如权利组织授权书或可证明代理人职务身份及公司授权代理人代表公司办理相关投诉事务的材料）。
          </li>
        </ul>
        <p class="bold">1.2 确保权属信息真实、完整无误且具备合法性：</p>
        <ul>
          <li class="bold">
            (1)
            知识产权权属证明材料：所提交材料需包括但不限于如著作权版权登记证、著作权授权合同、经权威机构签发的版权证书、作品备案证书等能证明权利人/权利组织拥有相关权利的有效权属证明。
          </li>
          <li class="bold">
            (2)
            个人民事权属证明材料：如名誉权、肖像权、隐私权等的投诉，应提供包括但不限于举报内容构成对权利人名誉权、肖像权、隐私权等权利侵权的证明材料。
          </li>
        </ul>
        <p>
          2.
          {{ appName }}作为中立的网络服务提供方，收到权利人/权利符合本指引要求的举报通知后，我们将按照届时有效的法律法规有关规定处理投诉人具体诉求并保障其权利人或权利组织的合法权益，并在符合相关法律法规规定的条件下将权利人的举报通知转至被举报方。
        </p>
        <p>
          3.
          本指引中的权利人/权利组织，指拥有既定知识产权等合法权益的原始所有人，代理人是指经原始所有人合法授权的受托人，包括自然人、法人或其他组织等。
        </p>
        <p>
          4.
          权利人/权利组织应提供经签章或签名的证明材料的扫描件或照片，为确保提供相关材料的真实性和有效性，若权利人/权利组织确有合理理由无法提供证明材料原件的，应提供相关材料的复印件（复印件上应有权利人/权利组织的签章或签名）。
        </p>
        <p>
          5.
          如若权利人/权利组织已对被举报人提起相关政府部门或法院进行司法争议或行政投诉渠道解决程序的，可将相关受理证明及提交政府部门或法院的证据材料一并提交给{{ appName }}，这将有利于权利人/权利组织的侵权举报处理
        </p>
        <p>
          6.
          本指引中的被举报方，均指上传图文/视频/音频内容的{{ appName }}用户，指引及政策仅适用于对中国站用户的举报，对于境外不同国家或地区的权利人/权利组织，应按照我国法律规定在所在国家或地区进行公证认证或其他法律要求的证明程序进行侵权举报流程发起。
        </p>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'ReportProcess',
    components: {},
    props: {},
    data() {
      return {
        reportProcessApplyFormUrl: `https://ld.kaixinyf.cn/assets/static/pdf/ky_report_process_apply_form.pdf`,
        appName: '',
        appNameMap: new Map([
          ['kuaiyin', { appName: '快音', companyName: '成都开心音符科技有限公司', companyAlias: '开心音符公司' }],
          [
            'kuaiyintingge',
            { appName: '快音听歌版', companyName: '成都超开心信息科技有限公司', companyAlias: '超开心公司' },
          ],
          [
            'kuaiyinjisu',
            { appName: '快音极速版', companyName: '成都超开心信息科技有限公司', companyAlias: '超开心公司' },
          ],
          ['kuaiyinyue', { appName: '快音悦', companyName: '成都开心音符科技有限公司', companyAlias: '开心音符公司' }],
          ['kuaiyinfm', { appName: '快音FM', companyName: '成都开心音符科技有限公司', companyAlias: '开心音符公司' }],
        ]),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      let app_name = 'kuaiyin';

      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      this.appName = this.appNameMap.get(app_name).appName;

      document.title = `${this.appName}App侵权举报流程指引`;
    },
    methods: {
      handleClick() {
        if (this.$platform.Android) {
          if (window.bridge) {
            window.bridge.closeWebView();
          }
        } else if (this.$platform.iOS) {
          this.$dsBridge.call('closeWebView');
        }
      },
    },
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  article {
    margin: 0 4%;
  }

  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  i {
    font-style: italic;
  }

  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t-head ul li {
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
    text-decoration: underline;
  }
</style>
